import {API_HOST, WS_HOST} from "@/util/api-host";
import axios from "axios";
import {authHeader} from "@/util/auth-header";

export const notificationsModule = {
    state: () => ({
        socket: {},
        notifications: [],
        notifications_map: {
            chat: {},
        },
    }),
    getters: {
        notifications(state) {
            return state.notifications;
        },
        notifications_map(state) {
            return state.notifications_map;
        },
    },
    mutations: {
        setSocket(state, new_socket) {
            state.socket = new_socket;
        },
        getNotifications(state, resp) {
            if (resp.data.message !== 'success') {
                console.error('unable to get notifications:', resp.data.message);
                return;
            }
            for (const notification of resp.data.payload.chat) {
                state.notifications_map['chat'][notification.chat_id] = {
                    n_unread: notification.n_unread,
                    last_unread: notification.last_unread,
                };
            }
        },
        updateNotifications(state, data) {
            if (data.payload.n_unread === 0) {
                delete state.notifications_map['chat'][data.payload.chat_id];
            } else {
                state.notifications_map['chat'][data.payload.chat_id] = {
                    n_unread: data.payload.n_unread,
                    last_unread: data.payload.last_unread,
                }
            }
        },
        makeNotificationsList(state) {
            state.notifications = [];
            for (const [chat_id, value] of Object.entries(state.notifications_map['chat'])) {
                state.notifications.push({
                    date: value.last_unread,
                    type: 'chat',
                    payload: {
                        chat_id: chat_id,
                        n_unread: value.n_unread,
                    },
                });
            }
            state.notifications.sort(function (lhs, rhs) {
                return lhs.date > rhs.date ? -1 : 1;
            });
        },
    },
    actions: {
        async initialize({commit, dispatch}) {
            await dispatch('auth/autoLogin', null, {root: true});
            return; // TODO: unlock notifications
            let socket = new WebSocket(WS_HOST + ":5008/ws?auth=" + localStorage.getItem("access_token"));
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'upd') {
                    commit('updateNotifications', data);
                }
                commit('makeNotificationsList');
            }
            commit('setSocket', socket);
            axios.get(
                API_HOST + ':5008/notifications',
                {
                    headers: authHeader(),
                }
            ).then(resp => {
                commit('getNotifications', resp);
                commit('makeNotificationsList');
            }).catch(err => {
                console.error(err);
            });
        },
    },
    namespaced: true,
};
