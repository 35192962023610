import {WebsocketProvider} from "y-websocket";
import {SERVICE_COLLAB_URI} from "@/util/api-host";

export const CRDTWSModule = {
    state: () => ({
        socket: null,
        is_socket_ready: false,
    }),
    getters: {
        socketReady(state) {
            return state.is_socket_ready;
        },
        awareness(state) {
            return state.socket ? state.socket.awareness : null;
        },
    },
    mutations: {
        setSocket(state, new_socket) {
            state.socket = new_socket;
        },
        killSocket(state) {
            if (state.socket) {
                state.socket.destroy();
            }
            state.socket = null;
        },
        setSocketReady(state, new_status) {
            state.is_socket_ready = new_status;
        },
    },
    actions: {
        initialize({commit, dispatch, getters}, payload) {
            if (getters['socketReady']) {
                return;
            }
            dispatch('auth/autoLogin', null, {root: true}).then(() => {
                const token = localStorage.getItem('refresh_token');
                if (!token) {
                    return;
                }
                let ws_provider = new WebsocketProvider(
                    SERVICE_COLLAB_URI + '/ws', payload.room_id, payload.ydoc, {
                        params: {
                            auth: token,
                        },
                    },
                );
                ws_provider.on('status', status => {
                    if (status.status === 'connected') {
                        commit('setSocketReady', true);
                        commit('setSocket', ws_provider);
                    } else if (status.status === 'disconnected') {
                        commit('setSocketReady', false);
                    }
                });
            });
        },
        destroy({commit}) {
            commit('setSocketReady', false);
            commit('killSocket');
        },
    },
    namespaced: true,
};