export function isRefreshTokenValid() {
  const token = localStorage.getItem('refresh_token');

  if (!token || token.split('.').length !== 3) {
    return false;
  }

  let data = JSON.parse(atob(token.split('.')[1]));
  return Date.now() < data.exp * 1000;
}
