<template>
  <div class="ytm-container">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Container',
};
</script>

<style scoped>
.ytm-container {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
}
</style>