import { createRouter, createWebHistory } from 'vue-router';
import store from "../store";

const LandingPage = () =>
    import(/* webpackChunkName: "group-landing" */ '../components/LandingPage/LandingPage');
const RegisterPage = () =>
    import(/* webpackChunkName: "group-register" */ '../components/LandingPage/RegisterPage/RegisterPage');
const LoginPage = () =>
    import(/* webpackChunkName: "group-login" */ '../components/LandingPage/LoginPage/LoginPage');
const TariffsPage = () =>
    import(/* webpackChunkName: "group-tariffs" */ '../components/LandingPage/TariffsPage/TariffsPage');
const FeaturesPage = () =>
    import(/* webpackChunkName: "group-features" */ '../components/LandingPage/FeaturesPage/FeaturesPage');
const TutorialPage = () =>
    import(/* webpackChunkName: "group-tutorial" */ '../components/Tutorial/Tutorial');
const CoursesPage = () =>
    import(/* webpackChunkName: "group-courses" */ '../components/CoursesPage/CoursesPage.vue');
const LessonsPage = () =>
    import(/* webpackChunkName: "group-lessons" */ '../components/LessonsPage/LessonsPage.vue');
const TimetablePage = () =>
    import(/* webpackChunkName: "group-timetable" */ '../components/TimetablePage/TimetablePage');
const CoursePageV2 = () =>
    import(/* webpackChunkName: "group-course-v2" */ '../components/CoursePageV2/CoursePage');
const LessonPage = () =>
    import(/* webpackChunkName: "group-lesson" */ '../components/LessonPage/LessonPage');
const HomeworkPage = () =>
    import(/* webpackChunkName: "group-homework" */ '../components/HomeworkPage/HomeworkPage');
const AccountPage = () =>
    import(/* webpackChunkName: "group-account" */ '../components/AccountPage/AccountPage');
const TeacherPage = () =>
    import(/* webpackChunkName: "group-profile" */ '../components/TeacherPage/TeacherPage');
const AdminPage = () =>
    import(/* webpackChunkName: "group-admin" */ '../components/Admin/AdminPage');
const TeacherApprovalsPage = () =>
    import(/* webpackChunkName: "group-teacher-approval" */ '../components/Admin/TeacherApprovals/TeacherApprovalsPage');
const AdminMailingPage = () =>
    import(/* webpackChunkName: "group-admin-mailing-page" */ '../components/Admin/Mailing/Mailing');
const AdminEditLetter = () =>
    import(/* webpackChunkName: "group-admin-mailing-page" */ '../components/Admin/Mailing/EditLetter/EditLetter');
const AdminEditMailing = () =>
    import(/* webpackChunkName: "group-admin-mailing-page" */ '../components/Admin/Mailing/EditMailing/EditMailing');

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: { requiresAuth: false, title: 'YouTeachMe | Платформа для репетитора' },
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage,
    meta: { requiresAuth: false, title: 'YouTeachMe | Регистрация' },
  },
  {
    path: '/login',
    name: 'NewLoginPage',
    component: LoginPage,
    meta: { requiresAuth: false, title: 'YouTeachMe | Вход' },
  },
  {
    path: '/tariffs',
    name: 'TariffsPage',
    component: TariffsPage,
    meta: { requiresAuth: false, title: 'YouTeachMe | Тарифы' },
  },
  {
    path: '/features',
    name: 'FeaturesPage',
    component: FeaturesPage,
    meta: { requiresAuth: false, title: 'YouTeachMe | Возможности платформы' },
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: TutorialPage,
    meta: { requiresAuth: false, title: 'YouTeachMe | Как пользоваться' },
  },
  {
    path: '/courses',
    name: 'CoursesPage',
    component: CoursesPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Мои курсы' },
  },
  {
    path: '/home',
    name: 'LessonsPage',
    component: LessonsPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Расписание' },
  },
  {
    path: '/course/:courseLink',
    name: 'CoursePageV2',
    component: CoursePageV2,
    meta: { requiresAuth: true, title: 'YouTeachMe | Платформа для онлайн-занятий' },
  },
  {
    path: '/course/:courseLink/lesson/:lessonId',
    name: 'LessonPage',
    component: LessonPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Платформа для онлайн-занятий'}
  },
  {
    path: '/course/:courseLink/homework/:homeworkId',
    name: 'HomeworkPage',
    component: HomeworkPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Домашнее задание'}
  },
  {
    path: '/account',
    name: 'SettingsPage',
    component: AccountPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Настройки' },
  },
  {
    path: '/profile/:username',
    name: 'TeacherPage',
    component: TeacherPage,
    meta: { requiresAuth: false, title: 'YouTeachMe | Профиль' },
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Админка' },
  },
  {
    path: '/admin/teacher_verification',
    name: 'TeacherApprovalPage',
    component: TeacherApprovalsPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Подтверждение профилей преподавателей' }
  },
  {
    path: '/admin/mailing',
    name: 'Mailing',
    component: AdminMailingPage,
    meta: { requiresAuth: true, title: 'YouTeachMe | Отправка рассылок' },
  },
  {
    path: '/admin/mailing/edit_letter/:letterId',
    name: 'EditLetter',
    component: AdminEditLetter,
    meta: { requiresAuth: true, title: 'YouTeachMe | Редактировать письмо' },
  },
  {
    path: '/admin/mailing/edit_mailing/:mailingId',
    name: 'EditMailing',
    component: AdminEditMailing,
    meta: { requiresAuth: true, title: 'YouTeachMe | Редактировать рассылку' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/autoLogin');
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  if (to.path === '/' || to.path === '/login' || to.path === '/register') {
    if (isAuthenticated) {
      next('/home');
    } else {
      document.title = to.meta.title || 'YouTeachMe';
      next();
    }
    return;
  }
  if (to.path.startsWith('/join')) {
    if (isAuthenticated) {
      document.title = 'YouTeachMe | Мои курсы';
      next('/courses?join=' + to.path.substr(6));
    } else {
      document.title = to.meta.title || 'YouTeachMe | Вход';
      next({
        path: '/login',
        query: {
          to: to.fullPath,
        },
      });
    }
    return;
  }
  if (to.path.startsWith('/admin')) {
    if (isAuthenticated && store.getters['auth/isAdmin']) {
      document.title = to.meta.title || 'YouTeachMe | Админка';
      next();
    } else {
      next('/home');
    }
    return;
  }
  if (!to.meta.requiresAuth || isAuthenticated) {
    document.title = to.meta.title || 'YouTeachMe';
    next();
  } else {
    document.title = to.meta.title || 'YouTeachMe | Вход';
    next({
      path: '/login',
      query: {
        to: to.fullPath,
      },
    });
  }
});

router.afterEach(async (to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
