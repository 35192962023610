<template>
  <div class="ytm-row">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Row',
};
</script>

<style scoped>
.ytm-row {
  width: 100%;
  display: flex;
}
</style>