import axios from "axios";
import {isAccessTokenValid} from "@/util/is-access-token-valid";
import {authHeader} from "@/util/auth-header";
import {SERVICE_AUTH_URI} from "@/util/api-host";
import router from "../../router";
import {isRefreshTokenValid} from "@/util/is-refresh-token-valid";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const authModule = {
    state: () => ({
        username: null,
        access_token: null,
        refresh_token: null,
        is_admin: false,
    }),
    getters: {
        isAuthenticated(state) {
            return state.username !== null;
        },
        isAdmin(state) {
            return state.is_admin;
        },
        username(state) {
            return state.username;
        },
        accessToken(state) {
            return state.access_token;
        },
        refreshToken(state) {
            return state.refresh_token;
        },
    },
    mutations: {
        setAuthData(state, user_data) {
            state.username = user_data.username;
            state.access_token = user_data.access_token;
            state.refresh_token = user_data.refresh_token;
            localStorage.setItem('username', user_data.username);
            localStorage.setItem('access_token', user_data.access_token);
            localStorage.setItem('refresh_token', user_data.refresh_token);
            const token = state.access_token;
            if (token && token.split('.').length === 3) {
                const data = JSON.parse(atob(token.split('.')[1]));
                state.is_admin = data.IsAdmin;
            }
        },
        removeAuthData(state) {
            state.username = null;
            state.access_token = null;
            state.refresh_token = null;
            state.is_admin = false;
            localStorage.removeItem('username');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            cookies.remove('username');
            cookies.remove('access-token');
            cookies.remove('refresh-token');
            cookies.remove('username', null, '.youteachme.ru');
            cookies.remove('access-token', null, '.youteachme.ru');
            cookies.remove('refresh-token', null, '.youteachme.ru');
        },
    },
    actions: {
        async login({commit}, {user_data, to}) {
            try {
                const response = await axios.post(
                    SERVICE_AUTH_URI + '/login',
                    {
                        username: user_data.username,
                        password: user_data.password,
                    }
                );
                commit('setAuthData', {
                    username: user_data.username,
                    access_token: response.data.out.access_token,
                    refresh_token: response.data.out.refresh_token,
                });
            } catch (err) {
                return {success: false};
            }
            await router.push(to || '/home');
            return {success: true};
        },
        async autoLogin({commit, dispatch}) {
            const username = cookies.get('username') || localStorage.getItem('username');
            const access_token = cookies.get('access-token') || localStorage.getItem('access_token');
            const refresh_token = cookies.get('refresh-token') || localStorage.getItem('refresh_token');
            cookies.remove('username');
            cookies.remove('access-token');
            cookies.remove('refresh-token');
            cookies.remove('username', null, '.youteachme.ru');
            cookies.remove('access-token', null, '.youteachme.ru');
            cookies.remove('refresh-token', null, '.youteachme.ru');
            if (!username || !access_token || !refresh_token) {
                commit('removeAuthData');
                return;
            }
            localStorage.setItem('username', username);
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            if (isAccessTokenValid()) {
                commit('setAuthData', {
                    username: username,
                    access_token: access_token,
                    refresh_token: refresh_token,
                });
            } else if (isRefreshTokenValid()) {
                try {
                    const response = await axios.post(
                        SERVICE_AUTH_URI + '/tokens/update',
                        {},
                        {
                            headers: authHeader(true),
                        },
                    );
                    commit('setAuthData', {
                        username: localStorage.getItem('username'),
                        access_token: response.data.out.access_token,
                        refresh_token: localStorage.getItem('refresh_token'),
                    });
                } catch (err) {
                    dispatch('logout');
                }
            } else {
                commit('removeAuthData');
            }
        },
        async logout({commit}) {
            try {
                await axios.post(SERVICE_AUTH_URI + '/logout/access', {}, {headers: authHeader()});
            } catch (err) { }
            try {
                await axios.post(SERVICE_AUTH_URI + '/logout/refresh', {}, {headers: authHeader(true)});
            } catch (err) { }
            commit('removeAuthData');
            await router.push('/');
        },
    },
    namespaced: true,
};
