<template>
    <div class="app">
        <router-view/>
    </div>
</template>

<script>
import {provide, ref} from "vue";

export default {
    setup() {
        let screen_width = ref(0);
        provide('screen_width', screen_width);
        return {screen_width};
    },
    methods: {
        checkWidth() {
            this.screen_width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        },
    },
    created() {
        this.checkWidth();
        window.addEventListener('resize', this.checkWidth);
    },
};
</script>

<style>
#app {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.5;
    scroll-behavior: smooth;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: content-box;
    height: 0;
    overflow: hidden;
}

html {
    font-size: 16px;
}
@media only screen and (max-width: 1250px) {
    html {
        font-size: 15px;
    }
}
@media only screen and (max-width: 950px) {
    html {
        font-size: 14px;
    }
}

a {
    color: #007bff;
    text-decoration: none;
}

[contenteditable] {
    outline: 0 solid transparent;
}

.ytm-text-mm {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
}

.ytm-text-l {
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: 500;
}

.ytm-text-xl {
    text-decoration: none;
    font-size: 1.7rem;
    font-weight: 500;
}

.ytm-text-xxl {
    text-decoration: none;
    font-size: 2rem;
    font-weight: 500;
}

.ytm-text-gray {
    color: gray;
}

.ytm-text-darkgray {
    color: rgba(73, 80, 87, 0.99);
}

.ytm-text-uppercase {
    text-transform: uppercase !important;
}

.text-left {
    text-align: left!important;
}

.text-center {
    text-align: center!important;
}

.text-right {
    text-align: right!important;
}

.ytm-w20 {
    width: 20%;
}

.ytm-w25 {
    width: 25%;
}

.ytm-w30 {
    width: 30%;
}
</style>
