import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export const coursesModule = {
    state: () => ({
        loading: false,
        failed: false,
        courses: null,
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        failed(state) {
            return state.failed;
        },
        courses(state) {
            return state.courses;
        },
        noCoursesAsTeacher(state) {
            return !state.courses || !state.courses.asTeacher || state.courses.asTeacher.length === 0;
        },
    },
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setFailed(state, value) {
            state.failed = value;
        },
        setCourses(state, courses) {
            state.courses = courses;
        },
    },
    actions: {
        fetchCourses({getters, commit, dispatch}) {
            return new Promise((resolve, reject) => {
                dispatch('auth/autoLogin', null, {root: true}).then(() => {
                    if (getters['loading']) {
                        resolve();
                        return;
                    }
                    commit('startLoading');
                    commit('setFailed', false);
                    axios.post(
                        SERVICE_MAIN_URI + '/getCoursesForUser',
                        {},
                        {headers: authHeader()},
                    ).then(resp => {
                        const courses = resp.data.out.courses;
                        for (const key in courses) {
                            courses[key].sort(function (lhs, rhs) {
                                return -lhs.lastEdited.localeCompare(rhs.lastEdited);
                            });
                        }
                        commit('setCourses', courses);
                        commit('stopLoading');
                        resolve();
                    }).catch(err => {
                        commit('setFailed', true);
                        commit('stopLoading');
                        reject(err);
                    });
                });
            });
        },
    },
    namespaced: true,
};