import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import components from './components/common';

const app = createApp(App);

app.config.compilerOptions.isCustomElement = (tag) => {
    return tag === 'math-field';
};

app.config.unwrapInjectedRef = true;

components.forEach(component => {
    app.component(component.name, component);
});

app.use(store).use(router).mount('#app');
