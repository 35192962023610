<template>
  <button type="button" class="ytm-button ytm-button-orange">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'Button',
};
</script>

<style>
.ytm-button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 450;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.ytm-button-orange {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.ytm-button-orange:focus, .ytm-button-orange:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(222 170 12 / 50%);
  box-shadow: 0 0 0 0.2rem rgb(222 170 12 / 50%);
}

.ytm-button-grey {
  color: #343a40;
  background-color: white;
  border-color: #343a40;
}

.ytm-button-grey:focus, .ytm-button-grey:hover {
  color: white;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
}
</style>