import {SERVICE_WEBSOCKET_URI} from "@/util/api-host";

export const websocketModule = {
    state: () => ({
        socket: null,
        is_socket_ready: false,
        chats: {},
        materialsRooms: {},
        boards: {},
        lessons: {},
    }),
    getters: {
        chats(state) {
            return state.chats;
        },
        materialsRooms(state) {
            return state.materialsRooms;
        },
        boards(state) {
            return state.boards;
        },
        lessons(state) {
            return state.lessons;
        },
        socketReady(state) {
            return state.is_socket_ready;
        },
    },
    mutations: {
        setSocket(state, new_socket) {
            state.socket = new_socket;
        },
        setSocketReady(state, new_status) {
            state.is_socket_ready = new_status;
        },
        closeConnection(state) {
            if (state.socket) {
                state.socket.onclose = null;
                state.socket.close();
            }
            state.chats = {};
            state.materialsRooms = {};
            state.boards = {};
        },
        sendMessage(state, message) {
            if (state.socket && state.is_socket_ready) {
                state.socket.send(JSON.stringify(message));
            }
        },
        addNewChat(state, chat) {
            state.chats[chat.chat_id] = chat;
        },
        removeChat(state, chat) {
            delete state.chats[chat.chat_id];
        },
        addNewMaterialsRoom(state, room) {
            state.materialsRooms[room.roomId] = room;
        },
        removeMaterialsRoom(state, room) {
            delete state.materialsRooms[room.roomId];
        },
        addNewBoard(state, board) {
            state.boards[board.board_id] = board;
        },
        removeBoard(state, board) {
            delete state.boards[board.board_id];
        },
        addNewLesson(state, lesson) {
            state.lessons[lesson.courseLink + ':' + lesson.lessonId] = lesson;
        },
        removeLesson(state, lesson) {
            delete state.lessons[lesson.courseLink + ':' + lesson.lessonId];
        },
    },
    actions: {
        async initialize({commit, dispatch, getters}) {
            await dispatch('auth/autoLogin', null, {root: true});
            const access = localStorage.getItem('access_token');
            if (!access) {
                return;
            }
            let socket = new WebSocket(SERVICE_WEBSOCKET_URI + '/ws?auth=' + access);
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                const payload = JSON.parse(data.p);
                // messenger:
                if (Math.floor(data.t / 1000) === 1) {
                    if (getters['chats'].hasOwnProperty(payload.chat_id)) {
                        getters['chats'][payload.chat_id].onNewSocketMessage(data.t, payload);
                    }
                }
                // materials:
                if (Math.floor(data.t / 1000) === 2) {
                    if (getters['materialsRooms'].hasOwnProperty(payload.materials_id)) {
                        getters['materialsRooms'][payload.materials_id].onNewSocketMessage(data.t, payload);
                    }
                }
                // board:
                if (Math.floor(data.t / 1000) === 3) {
                    const bId = payload.fId.split(':', 1)[0];
                    if (getters['boards'].hasOwnProperty(bId)) {
                        getters['boards'][bId].onNewSocketMessage(data.t, data.a, payload);
                    }
                }
                // lesson:
                if (Math.floor(data.t / 1000) === 4) {
                    if (getters['lessons'].hasOwnProperty(payload.lesson)) {
                        getters['lessons'][payload.lesson].onNewSocketMessage(data.t, payload);
                    }
                }
            };
            socket.onopen = () => {
                commit('setSocketReady', true);
            };
            socket.onerror = () => {
                socket.close();
            };
            socket.onclose = () => {
                commit('setSocketReady', false);
                setTimeout(
                    () => {dispatch('initialize')},
                    1000,
                );
            };
            commit('setSocket', socket);
        },
        closeConnection({commit}) {
            commit('setSocketReady', false);
            commit('closeConnection');
            commit('setSocket', null);
        },
        /** CHATS **/
        registerChat({commit}, chat) {
            commit('addNewChat', chat);
        },
        sendChatMessage({commit}, payload) {
            commit('sendMessage', {
                t: 1001,
                p: JSON.stringify(payload),
            });
        },
        refocusChat({commit}, payload) {
            commit('sendMessage', {
                t: 1002,
                p: JSON.stringify(payload),
            });
        },
        readChat({commit}, payload) {
            commit('sendMessage', {
                t: 1003,
                p: JSON.stringify(payload),
            });
        },
        unregisterChat({commit}, chat) {
            commit('removeChat', chat);
        },
        /** MATERIALS **/
        registerMaterialsRoom({commit}, room) {
            commit('addNewMaterialsRoom', room);
        },
        refocusMaterialsRoom({commit}, payload) {
            commit('sendMessage', {
                t: 2001,
                p: JSON.stringify(payload),
            });
        },
        sendCRDTUpdate({commit}, payload) {
            commit('sendMessage', {
                t: 2002,
                p: JSON.stringify(payload),
            });
        },
        unregisterMaterialsRoom({commit}, room) {
            commit('removeMaterialsRoom', room);
        },
        /** BOARDS **/
        registerBoard({commit}, board) {
            commit('addNewBoard', board);
        },
        refocusBoard({commit}, payload) {
            commit('sendMessage', {
                t: 3001,
                p: JSON.stringify(payload),
            });
        },
        sendMouseMove({commit}, payload) {
            commit('sendMessage', {
                t: 3002,
                p: JSON.stringify(payload),
            });
        },
        sendObjectAddition({commit}, payload) {
            commit('sendMessage', {
                t: 3003,
                p: JSON.stringify(payload),
            });
        },
        sendObjectTransform({commit}, payload) {
            commit('sendMessage', {
                t: 3004,
                p: JSON.stringify(payload),
            });
        },
        sendObjectDeletion({commit}, payload) {
            commit('sendMessage', {
                t: 3005,
                p: JSON.stringify(payload),
            });
        },
        unregisterBoard({commit}, board) {
            commit('removeBoard', board);
        },
        /** LESSONS **/
        registerLesson({commit}, lesson) {
            commit('addNewLesson', lesson);
        },
        refocusLesson({commit}, payload) {
            commit('sendMessage', {
                t: 4001,
                p: JSON.stringify(payload),
            });
        },
        unregisterLesson({commit}, lesson) {
            commit('removeLesson', lesson);
        },
    },
    namespaced: true,
};
