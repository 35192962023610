<template>
  <div class="ytm-col">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Column',
};
</script>

<style scoped>
.ytm-col {
  width: 100%;
  position: relative;
}
</style>