import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import lessonStatuses from "@/util/lesson_statuses";

export const lessonModule = {
    state: () => ({
        lesson: null,
        lessonStatus: lessonStatuses.BEFORE,
        loading: false,
        failed: false,
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        failed(state) {
            return state.failed;
        },
        lesson(state) {
            return state.lesson;
        },
        lessonStatus(state) {
            return state.lessonStatus;
        },
    },
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setFailed(state, value) {
            state.failed = value;
        },
        setLesson(state, lesson) {
            state.lesson = lesson;
        },
        setLessonStatus(state, status) {
            state.lessonStatus = status;
        },
        clearLessonInfo(state) {
            delete state.lesson;
            state.lesson = null;
            state.loading = false;
        },
    },
    actions: {
        fetch({getters, commit, dispatch}, {courseLink, lessonId}) {
            return new Promise((resolve, reject) => {
                dispatch('auth/autoLogin', null, {root: true}).then(() => {
                    if (getters['loading']) {
                        resolve('already loading');
                        return;
                    }
                    commit('setFailed', false);
                    commit('startLoading');
                    axios.get(
                        SERVICE_MAIN_URI + '/lesson',
                        {
                            params: {
                                courseId: courseLink,
                                lessonId: lessonId,
                            },
                            headers: authHeader(),
                        },
                    ).then(resp => {
                        const respLesson = resp.data.out.lesson;
                        const lesson = {
                            courseLink: courseLink,
                            lessonId: lessonId,
                            topic: respLesson.topic,
                            materialsRoomId: respLesson.materialsRoomId,
                            roleId: resp.data.out.roleId,
                        };
                        if (respLesson.dtStart) {
                            lesson.dtStart = new Date(respLesson.dtStart);
                            lesson.dtFinish = new Date(respLesson.dtFinish);
                        }
                        if (respLesson.startedAt) {
                            lesson.startedAt = new Date(respLesson.startedAt);
                            lesson.finishedAt = new Date(respLesson.finishedAt);
                        }
                        commit('setLesson', lesson);
                        const now = new Date();
                        if (!lesson.startedAt/* || now < lesson.startedAt*/) {
                            // because timestamp comparison may fail: time on backend and on frontend may be different
                            commit('setLessonStatus', lessonStatuses.BEFORE);
                        } else if (now < lesson.finishedAt) {
                            // but if startedAt is defined, we know that lesson is at least running
                            commit('setLessonStatus', lessonStatuses.RUNNING);
                        } else {
                            commit('setLessonStatus', lessonStatuses.AFTER);
                        }
                        commit('stopLoading');
                        resolve();
                    }).catch(err => {
                        commit('setFailed', true);
                        commit('stopLoading');
                        reject(err);
                    });
                });
            });
        },
        actualizeLessonStatus({getters, commit}, value) {
            if (value !== null && value !== undefined) {
                commit('setLessonStatus', value);
            }
        },
        clear({commit}) {
            commit('clearLessonInfo');
        },
    },
    namespaced: true,
};
