<template>
  <input
      :value="modelValue"
      @input="updateInput"
      class="ytm-input"
      :type="type"
      :placeholder="placeholder"
  >
</template>

<script>
export default {
  name: 'Input',
  props: {
    modelValue: {},
    type: {type: String},
    placeholder: {type: String},
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped>
.ytm-input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-family: inherit;
  overflow: hidden;
}

.ytm-input:focus {
  outline-color: lightgray;
}
</style>
