import axios from "axios";
import {SERVICE_MAIN_URI, SERVICE_USER_PROFILE_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export const userInfoModule = {
    state: {
        loading: false,
        failed: false,
        user_info: null,
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        failed(state) {
            return state.failed;
        },
        user_info(state) {
            return state.user_info;
        },
    },
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setFailed(state, value) {
            state.failed = value;
        },
        setUserInfo(state, user_info) {
            state.user_info = user_info;
        },
    },
    actions: {
        fetchUserInfo({getters, commit, dispatch}) {
            return new Promise((resolve, reject) => {
                dispatch('auth/autoLogin', null, {root: true}).then(() => {
                    if (getters['loading']) {
                        resolve();
                        return;
                    }
                    commit('startLoading');
                    axios.get(
                        SERVICE_USER_PROFILE_URI + '/users/get',
                        {headers: authHeader()},
                    ).then(resp => {
                        let new_state = {};
                        new_state.user_info = resp.data.out;
                        if (!new_state.user_info.teacherState) {
                            new_state.user_info.teacherState = {isT: false};
                        }
                        new_state.user_info.phoneNumber = new_state.user_info.phoneNumber || '';
                        if (new_state.user_info.phoneNumber) {
                            new_state.user_info.phoneNumber = '+' + new_state.user_info.phoneNumber;
                        }
                        new_state.user_info.email = new_state.user_info.email || '';
                        commit('setUserInfo', new_state.user_info);
                        commit('setFailed', false);
                        commit('stopLoading');
                        resolve();
                    }).catch(err => {
                        commit('setFailed', true);
                        commit('stopLoading');
                        reject(err);
                    });
                });
            });
        },
        editUserInfo({commit, dispatch}, payload) {
            dispatch('auth/autoLogin', null, {root: true}).then(() => {
                axios.put(
                    SERVICE_USER_PROFILE_URI + '/users/update',
                    payload,
                    {headers: authHeader()},
                ).then(resp => {
                    dispatch('fetchUserInfo');
                }).catch(err => {
                    console.error(err);
                });
            });
        },
        updateAvatar({commit, dispatch}, payload) {
            dispatch('auth/autoLogin', null, {root: true}).then(() => {
                axios.put(
                    SERVICE_USER_PROFILE_URI + '/users/update/avatar',
                    payload,
                    {
                        headers: {
                            ...authHeader(),
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                ).then(resp => {
                    dispatch('fetchUserInfo');
                }).catch(err => {
                    console.error(err);
                });
            });
        },
        becomeTeacher({dispatch}) {
            return new Promise((resolve, reject) => {
                dispatch('auth/autoLogin', null, {root: true}).then(() => {
                    axios.post(
                        SERVICE_USER_PROFILE_URI + '/teacher/become',
                        {},
                        {
                            headers: authHeader(true),
                        },
                    ).then(resp => {
                        dispatch('fetchUserInfo').then(() => {resolve()}).catch(err => {reject(err)});
                    }).catch(err => {
                        reject(err);
                    });
                });
            });
        },
    },
    namespaced: true,
};