import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export const courseModule = {
    state: () => ({
        course: null,
        lessons: null,
        loading: false,
        failed: false,
    }),
    getters: {
        loading(state) {
            return state.loading;
        },
        failed(state) {
            return state.failed;
        },
        course(state) {
            return state.course;
        },
        lessons(state) {
            return state.lessons;
        },
    },
    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setFailed(state, value) {
            state.failed = value;
        },
        setCourse(state, course) {
            state.course = course;
        },
        setLessons(state, lessons) {
            state.lessons = lessons;
        },
        clearCourseInfo(state) {
            delete state.course;
            state.course = null;
            delete state.lessons;
            state.lessons = null;
            state.loading = false;
        },
    },
    actions: {
        fetch({getters, commit, dispatch}, course_link) {
            return new Promise((resolve, reject) => {
                dispatch('auth/autoLogin', null, {root: true}).then(() => {
                    if (getters['loading']) {
                        resolve('already loading');
                        return;
                    }
                    commit('setFailed', false);
                    commit('startLoading');
                    axios.get(
                        SERVICE_MAIN_URI + '/course/full',
                        {
                            params: {courseId: course_link},
                            headers: authHeader(),
                        },
                    ).then(resp => {
                        let course = resp.data.out;
                        course.lessons.sort((lhs, rhs) => {
                            if (!lhs.dtStart && !rhs.dtStart) {
                                return lhs.lessonId < rhs.lessonId ? -1 : lhs.lessonId === rhs.lessonId ? 0 : 1;
                            } else if (!lhs.dtStart) {
                                return 1;
                            } else if (!rhs.dtStart) {
                                return -1;
                            } else {
                                return -lhs.dtStart.localeCompare(rhs.dtStart);
                            }
                        });
                        for (const lesson of course.lessons) {
                            if (lesson.dtStart) {
                                lesson.dtStart = new Date(lesson.dtStart);
                                lesson.dtFinish = new Date(lesson.dtFinish);
                            }
                            if (lesson.startedAt) {
                                lesson.startedAt = new Date(lesson.startedAt);
                                lesson.finishedAt = new Date(lesson.finishedAt);
                            }
                        }
                        let lessons = {};
                        for (const lesson of course.lessons) {
                            lessons[lesson.lessonId] = lesson;
                        }
                        commit('setCourse', course);
                        commit('setLessons', lessons);
                        commit('stopLoading');
                        resolve();
                    }).catch(err => {
                        commit('setFailed', true);
                        commit('stopLoading');
                        reject(err);
                    });
                });
            });
        },
        clear({commit}) {
            commit('clearCourseInfo');
        },
    },
    namespaced: true,
};
