let api_host = '';
const homeIP = '192.168.0.12';
// const homeIP = 'localhost';

if (process.env.VUE_APP_PRODUCTION_HOST) {
    api_host = 'https://' + process.env.VUE_APP_PRODUCTION_HOST;
} else {
    api_host = 'http://' + homeIP;
}

export const API_HOST = api_host;
export const WS_HOST = 'ws://' + (process.env.VUE_APP_PRODUCTION_HOST || homeIP);

export const SERVICE_AUTH_URI = process.env.VUE_APP_SERVICE_AUTH_URI || API_HOST + ':5001';
export const SERVICE_MAIN_URI = process.env.VUE_APP_SERVICE_MAIN_URI || API_HOST + ':5000';
export const SERVICE_MESSENGER_URI = process.env.VUE_APP_SERVICE_MESSENGER_URI || API_HOST + ':5005';
export const SERVICE_BOARD_URI = process.env.VUE_APP_SERVICE_BOARD_URI || API_HOST + ':5006';
export const SERVICE_MATERIALS_URI = process.env.VUE_APP_SERVICE_MATERIALS_URI || API_HOST + ':5007';
export const SERVICE_USER_PROFILE_URI = process.env.VUE_APP_SERVICE_USER_PROFILE_URI || API_HOST + ':5004';
export const SERVICE_ACCOUNTANT_URI = process.env.VUE_APP_SERVICE_ACCOUNTANT_URI || API_HOST + ':5011';
export const SERVICE_MAILING_URI = process.env.VUE_APP_SERVICE_MAILING_URI || API_HOST + ':5012';

export const SERVICE_WEBSOCKET_URI = process.env.VUE_APP_SERVICE_WEBSOCKET_URI || WS_HOST + ':5002';
export const SERVICE_COLLAB_URI = process.env.VUE_APP_SERVICE_COLLAB_URI || WS_HOST + ':5009';