import { createStore } from 'vuex';
import {authModule} from "./modules/auth";
import {notificationsModule} from "./modules/notifications";
import {courseModule} from "./modules/course";
import {userInfoModule} from "./modules/userinfo";
import {websocketModule} from "@/store/modules/ws";
import {CRDTWSModule} from "@/store/modules/crdt_ws";
import {coursesModule} from "@/store/modules/courses";
import {lessonModule} from "@/store/modules/lesson";

export default createStore({
  modules: {
    auth: authModule,
    notifications: notificationsModule,
    courses: coursesModule,
    course: courseModule,
    lesson: lessonModule,
    user_info: userInfoModule,
    ws: websocketModule,
    crdt_ws: CRDTWSModule,
  },
});
